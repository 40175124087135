<template>
  <div class="MaintenanceActions">
    <div ref="dots"
         class="dots-button">
      <icon-dots-horizontal width="16"
                            height="16"
                            color="rgb(178, 182, 195)" />
    </div>

    <div ref="dropdown">
      <nav class="nav">
        <router-link class="nav-item" :to="editRoute">Edit</router-link>
        <a href="#"
           class="nav-item"
           @click.prevent="onDelete">Remove</a>
      </nav>
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js'
import swal from 'sweetalert2'

export default {
  props: {
    maintenanceItem: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      tippyInstance: null
    }
  },

  mounted () {
    this.initDropdown()
  },

  methods: {
    initDropdown () {
      this.tippyInstance = tippy(this.$refs.dots, {
        content: this.$refs.dropdown,
        interactive: true,
        theme: 'light-border',
        animation: 'shift-away-subtle',
        placement: 'left',
        arrow: true,
        inertia: true,
        trigger: 'click',
        maxWidth: 'none'
      })
    },

    async onDelete () {
      swal.fire({
        html: '<h4>' + 'Are you sure that you want to permanently delete "' + this.maintenanceItem.name + '" item?</h4>',
        title: '',
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        showCancelButton: true,
        confirmButtonColor: '#eb5757',
        cancelButtonColor: '#9ca3a1',
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel'
      }).then(async (val) => {
        if (val.value === true) {
          try {
            this.tippyInstance.hide()
            await this.refreshCurrentUser()
            this.$emit('delete', this.maintenanceItem.id)
          } catch (e) {
            alert('Something went wrong!')
          }
        }
      })
    },

    ...mapActions('authentication', [
      'refreshCurrentUser'
    ])
  },

  computed: {
    editRoute () {
      return {
        name: 'statusPages.single.maintenance.single',
        params: {
          maintenanceId: this.maintenanceItem.id
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.MaintenanceActions {
  .dots-button {
    @include inline-flex-center;

    cursor: pointer;
    transition: 0.2s all;
    padding: 8px;
    border-radius: 4px;

    &:hover {
      background-color: #f6f5f7;

      ::v-deep svg g {
        fill: map-get($colors, purple-1) !important;
      }
    }
  }

  .nav {
    width: 140px;

    .nav-item {
      display: block;
      padding: 8px 10px;
      color: map-get($colors, gray-1);
      font-size: 15px;
      text-decoration: none;
      font-weight: 500;
      border-radius: 4px;

      &:hover {
        background-color: map-get($colors, purple-1);
        color: #fff;
      }
    }
  }
}
</style>
